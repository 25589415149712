<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">구매 요청 정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 192px" />
          <col />
          <col style="width: 192px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>정산서구분</th>
            <td>{{ settlementTypeName }}</td>
            <th>정산서 등록일</th>
            <td>{{ regDate }}</td>
          </tr>
          <tr>
            <th>정산서 번호</th>
            <td>{{ model.settlementCid }}</td>
            <th>기안 번호</th>
            <td>{{ model.voucherKey }}</td>
          </tr>
          <tr>
            <th>구매 요청 정보</th>
            <!-- v-if="!docflagYn" -->
            <td colspan="3">
              <ConnectViewOnlyOne
                :dataList="model.purchaseRequestWf"
                :isDisabled="model.purchaseRequestWf.aprvlStatus == '-'"
                @onClickConnect="onClickConnectPurchaseRequestWf"
              />
            </td>
            <!-- <td v-else colspan="3">
              <div class="row_pack">
                <button type="button" class="btn_small btn_fourthly" @click="$emit('onClickPurchaseRequest')">구매 요청 정보 불러오기</button>
                <p class="desc_tip"><span class="ico_account ico_exclam"></span>구매 요청 정보를 선택하여 계약정보를 함꼐 불러올 수 있습니다.</p>
              </div>
              <ConnectEdit
                :dataList="purchaseRequestWf"
                :disabledData="{ aprvlStatus: '-' }"
                @onClickConnect="onClickConnectPurchaseRequestWf" />
            </td> -->
          </tr>
          <tr>
            <th>등록 정산서</th>
            <td colspan="3">
              <ConnectView
                titleKeyName="settlementName"
                :dataList="settlementHistories"
                :disabledData="{ aprvlStatus: '-' }"
                @onClickConnect="onClickConnectStaementInfo"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ConnectViewOnlyOne from "@/components/common/connect/ConnectViewOnlyOne.vue";

import ConnectView from "@/components/common/connect/ConnectView";
import ConnectEdit from "@/components/common/connect/ConnectEdit";
import { getPurchaseDraftDetailPath, getStatementDetailPath } from "@/utils/DetailPathUtils";

export default {
  name: "PurchaserequestInfo",
  components: {
    ConnectViewOnlyOne,
    ConnectView,
    ConnectEdit,
  },
  props: {
    model: Object,
    connectDataList: Array,
    docflag: String,
    regDate: String,
  },
  data() {
    return {
      title: "",
    };
  },
  computed: {
    settlementHistories() {
      return this.model.settlementHistories;
    },
    purchaseRequestWf() {
      const list = [];

      if (this.model.purchaseRequestWf.requestCid != "") {
        list.push(this.model.purchaseRequestWf);
      }

      return list;
    },
    settlementTypeName() {
      switch (this.model.settlementType) {
        case "OL":
          return "일반구매";
        case "IF":
          return "자산구매";
        default:
          return "";
      }
    },
  },
  mounted() {},
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    onClickConnectPurchaseRequestWf(item) {
      const { requestCid } = item;
      const path = `${this.$routerPath.PURCHASE_VIEW}/${requestCid}`;
      this.$windowOpen(path);
    },
    onClickConnectStaementInfo(item) {
      console.log(item);
      const { settlementCid } = item;
      const path = `${this.$routerPath.STATEMENT_VIEW}/${settlementCid}`;
      this.$windowOpen(path);
    },
  },
};
</script>
